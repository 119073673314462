<template>
  <div id="blank-layout">
    <slot />
  </div>
</template>

<style>
#blank-layout {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
}
</style>
